import React, {useEffect, useState} from "react";
import {countItemsContaining, deleteStorage, getStorage, setStorage} from "../utils/storage";
import {Link, useNavigate} from "react-router-dom";
import {useDate} from "../useDate";
import {ActionSheet, ActionSheetButton, BottomToolbar, Button, Checkbox, Page, Select, Toolbar} from "react-onsenui";
import {paths} from "../App";
import {makeDate_Std} from "../utils/date-helper";
/* QR/Barcode scanner */
import Html5QrcodePlugin from "../Html5QrcodePlugin";
import {storageCleanReset} from "../utils/storage-clean-reset";

export const ChecklistsDashboard = () => {

    const [authUser, setAuthUser] = useState(getStorage('u4'));

    /* Role */
    const defaultRole = { name:"Not set", value:0 };
    const [authUserRole, setAuthUserRole] = useState(getStorage('u4R'));
    useEffect(() => {
        setStorage('u4R',authUserRole);
    },[authUserRole]);

    let navigate = useNavigate();

    const [enableQRScanner, setEnableQRScanner] = React.useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const dateRT = useDate();

    const onNewScanResult = (decodedText,decodedResult) => {
        if(isValidQRCode(decodedText)) {
            let dataPayload = {
                decodedText: decodedText,
            }
            navigate(paths.checklist.details,{state:{data:dataPayload}});
        }
    }

    const performLogout = () => {
        deleteStorage('u4');
        deleteStorage('u4R');
        navigate(paths.root.login);
    }
    
    return (
        <Page renderToolbar={() =>
            <Toolbar>
                <div className="left">
                    <span className="p-l-10"><span className="fa fa-user-shield"></span>{' '}<b>{authUser?.fname||''}</b></span>
                </div>
                <div className="center">
                    <small className="p-l-10 f-s-08r">{dateRT}</small>
                </div>
                <div className="right">
                    <Button modifier="quiet" onClick={()=>setShowOptions(true)}><span className="fa fa-cogs"></span></Button>
                    <label className="flex flex-both-center p-r-10" style={{justifyContent:'right'}}>
                        <Checkbox
                            onChange={(event)=>{}}
                            modifier='' />
                        &nbsp; Compact
                    </label>
                </div>
            </Toolbar> }>
            <div className="p-l-r-15 p-t-10">

                <div className="flex flex-dir-row flex-vert-center flex-horiz-space-bw m-b-15">
                    <span className="f-s-08r l-h-1r f-w-500">Primary role</span>
                    <Select modifier="default" value={authUserRole} onChange={ (e) => { setAuthUserRole(e?.target?.value); } }>
                        { authUser?.roles?.map( role => {
                            return <option value={role.id} key={role.id}>{role.name}</option>
                        })}
                        <option value={defaultRole.value}>{defaultRole.name}</option>
                    </Select>
                </div>

                {/* QR CODE SCANNER */}
                { enableQRScanner &&
                    <Html5QrcodePlugin
                        fps={4}
                        qrbox={550}
                        disableFlip={false}
                        qrCodeSuccessCallback={onNewScanResult}
                    />
                }
                {/* QR CODE SCANNER BUTTON */}
                { !enableQRScanner &&
                    <div className="text-a-center flex flex-both-center" style={{minHeight:300,backgroundColor:"rgba(0,0,0,0.05)",borderRadius:5}}>
                        <Button modifier="large--quiet" disabled={enableQRScanner} onClick={()=>{setEnableQRScanner(!enableQRScanner)}}>
                            Scan Checklist QR
                        </Button>
                        <div className="spacer d-b p-t-10"></div>
                    </div>
                }

                {/* DEV LOCAL: Test QR Button */}
                {/* ====================================== */}
                { process.env.NODE_ENV==='development' ?
                    <Button style={{margin:'10px 0', width:'100%',textAlign:'center'}} onClick={()=>onNewScanResult(
                        "https://atom.checkinn.co/r?hash=eyJpZCI6MjI4LCJnZWVrIjoiQHRoaW5rZGoiLCJicGkiOjEsIm9pIjoxMDAxLCJsb2kiOjEsImJwbiI6Ildhc2hSb29tIFRlbXBsYXRlIiwibG9uIjoiTWVuJ3MgV2FzaHJvb20ifQ=="
                    )}>Scan QR (+!DEV!+)</Button>
                    : <></> }
                {/* ====================================== */}

                <div className="text-a-center flex flex-both-center flex-dir-col w-100-pc p-t-20" style={{minHeight:20}}>
                    <Link to={paths.checklist.myClDrafts}>
                        <Button modifier="quiet">Unsubmitted Checklists <small className="notification notification--smaterial">{countItemsContaining('cache_instance_')}</small></Button>
                    </Link>
                </div>

                <div className="text-a-center flex flex-both-center flex-dir-col w-100-pc p-t-20" style={{minHeight:20}}>
                    <Link to={paths.checklist.myInstances}>
                        <Button modifier="quiet">View my completed Checklists</Button>
                    </Link>
                </div>

            </div>

            {/* Options */}
            <ActionSheet isOpen={showOptions} isCancelable={false}>
                {/* <ActionSheetButton onClick={()=>{}}>
                    My Profile
                </ActionSheetButton> */}
                <ActionSheetButton onClick={()=> navigate(paths.checklist.mySettings)}>
                    Settings
                </ActionSheetButton>
                <ActionSheetButton onClick={() => {
                    const confirmed = window.confirm('This action cannot be undone. This will clear all unsubmitted checklists, login info and caches. Are you sure?');
                    if (confirmed) {
                        storageCleanReset();
                    }
                }}>
                    Reset storage
                </ActionSheetButton>
                <ActionSheetButton onClick={performLogout} modifier="destructive">
                    Logout
                </ActionSheetButton>
                <ActionSheetButton onClick={()=>setShowOptions(false)}>
                    Cancel
                </ActionSheetButton>
            </ActionSheet>

            <PageFooter />

        </Page>
    );
}

const isValidQRCode = (string) => {
    return !!string; // change logic
}

const PageFooter = () => {
    {/* Bottom Toolbar */}
    return (
        <BottomToolbar style={{zIndex:-1,position:"fixed",bottom:0}}>
            <div className="p-15 f-s-09r f-w-500 app-font-alt" style={{lineHeight:1}}>
                <small>{makeDate_Std(new Date().toISOString())}</small>
            </div>
        </BottomToolbar>
    );
}
