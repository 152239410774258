import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

/* SENTRY */
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {getEnvVar} from "./config.common";

Sentry.init({
    dsn: getEnvVar("SENTRY_DSN",null),
    // integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],
    // WARNING: We recommend adjusting this value in production, or using tracesSampler for finer control
    tracesSampleRate: 0.005,
});
/* */

ReactDOM.render(
  /*<React.StrictMode>*/
    <App />
  /*</React.StrictMode>*/
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
