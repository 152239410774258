import React, { useEffect, useState } from "react";
import { getItem, getStorage, setStorage } from "../utils/storage";
import { useNavigate } from "react-router-dom";
import {
    BackButton,
    List,
    ListItem,
    Page,
    Toolbar,
    ProgressCircular,
    Button
} from "react-onsenui";
import { useDate } from "../useDate";

import { axiosInstance as axios } from "../utils/axios";
import Select from "react-select";

export const ChecklistMySettings = () => {
    const [authUser, setAuthUser] = useState(getStorage("u4"));
    const dateRT = useDate();
    /* Navigation */
    let navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [timeZones, setTimeZones] = useState([]);
    const getTimeZones = async () => {
      const response = await axios.get('/list/timezones');
      setTimeZones(response.data);
    };

    useEffect(()=>{
       getTimeZones()
    },[])

    
    let timeOptions=[];
    if(timeZones.length!= 0){
        timeOptions= timeZones.map((item,index)=>{
            return(
                {value:index, label:`${item.name} ${item.delta_from_UTC}`}
            )
        })
    }

    const [selectedTimezone, setSelectedTimezone]= useState("");
    
    const changeTimezone=async()=>{
        let r= await axios.post('/user/timezone', {timezone:timeZones[selectedTimezone].name})
        setStorage("u4",{...authUser, timezone:timeZones[selectedTimezone].name})
        setAuthUser(getStorage("u4"))
        
    }

    return (
        <>
            <Page
                renderToolbar={() => (
                    <Toolbar>
                        <div className="left">
                            <BackButton onClick={goBack}>Back</BackButton>
                        </div>
                        <div className="center">
                            <small>{dateRT}</small>
                        </div>
                        <div className="right">{/* RHS */}</div>
                    </Toolbar>
                )}
            >
                <div className="p-l-r-15 m-b-20 m-t-15">
                    <h3
                        style={{
                            lineHeight: "1.32",
                            borderLeft: "3px solid #1e88e5",
                            paddingLeft: 12,
                        }}
                    >
                        <span>My Settings</span>
                        <small className="opa-050 d-b f-s-094r">
                            Change Timezone, etc
                        </small>
                    </h3>
                </div>
                <div className="p-l-r-15 m-b-20 m-t-25">
                    <h4 style={{ fontWeight: "bold" }}>Change Timezone</h4>
                    <div className="p-l-r-15 m-b-20 m-t-20" >
                    <span>Your Timezone is set to: <span style={{fontWeight:"bold",textDecoration:"underline",padding:'0 5px'}}>{authUser.timezone}</span></span>
                    <div className="m-b-15 m-t-15" style={{width:"300px"}}>
                        <Select
                            placeholder="Change Timezone"
                            // defaultValue={colourOptions[0]}
                            isSearchable={true}
                            name="time"
                            options={timeOptions}
                            onChange={(e)=>setSelectedTimezone(e.value)}
                        />
                        <Button style={{marginTop:10}} onClick={changeTimezone}>
                            Update Timezone
                        </Button>
                    </div>
                    </div>
                </div>
            </Page>
        </>
    );
};
