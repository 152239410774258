
export const storageCleanReset = (reloadPage=true) => {
    // Clear localStorage
    localStorage.clear();

    // Clear sessionStorage
    sessionStorage.clear();

    // Clear indexedDB
    const deleteIndexedDB = () => {
        if (indexedDB.webkitGetDatabaseNames) {
            // For WebKit-based browsers
            const databases = indexedDB.webkitGetDatabaseNames();
            databases.onsuccess = (event) => {
                const dbNameArray = event.target.result;
                dbNameArray.forEach((dbName) => {
                    indexedDB.deleteDatabase(dbName);
                });
            };
        } else {
            // For all other browsers, use a predefined list of database names
            const dbNameArray = []; // Replace with your actual database names
            dbNameArray.forEach((dbName) => {
                indexedDB.deleteDatabase(dbName);
            });
        }
    };
    deleteIndexedDB();

    // Clear cookies
    const deleteAllCookies = () => {
        document.cookie.split(";").forEach((c) => {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
    };
    deleteAllCookies();

    // Clear cache
    const deleteCache = async () => {
        if ('caches' in window) {
            const cacheNames = await caches.keys();
            cacheNames.forEach(async (cacheName) => { await caches.delete(cacheName); });
        }
    };
    deleteCache().then(()=>{console.log("Cache cleared")});
    
    // UnRegister service workers
    const unregisterServiceWorker = async () => {
        if ('serviceWorker' in navigator) {
            const registrations = await navigator.serviceWorker.getRegistrations();
            for (const registration of registrations) {
                await registration.unregister();
            }
        }
    };
    unregisterServiceWorker().then(()=>{console.log("Service workers unregistered")});


    // Reload the page
    // eslint-disable-next-line no-restricted-globals
    if(!!reloadPage) location.reload();
    
}