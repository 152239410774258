import React, {useEffect, useState} from "react";
import {getItem, getStorage} from "../utils/storage";
import {useNavigate} from "react-router-dom";
import {BackButton, List, ListItem, Page, Toolbar, ProgressCircular,Button,AlertDialog} from "react-onsenui";
import {useDate} from "../useDate";
import {paths} from "../App";
import {makeDate_Std} from "../utils/date-helper";
import {storageCleanReset} from "../utils/storage-clean-reset";

export const ChecklistMySavedDrafts = (props) => {
    
    const cache_key = 'cache_instance_';

    const [authUser, setAuthUser] = useState(getStorage('u4'));
    const dateRT = useDate();

    /* Navigation */
    let navigate = useNavigate();
    const goBack = () => navigate(paths.checklist.dash, {replace:true});

    const renderListHeader = () => <></>;
    const renderListFooter = () => <></>;

    const [myInstanceDrafts, setMyInstanceDrafts] = useState(null);
    const getMyClInstances = async () => {
        if(!authUser || !authUser?.id) return;
        let lsArrayFiltered = [];
        let lsArray = Object.keys(localStorage);
        //NOTE: for i < localStorage.length was IGNORING some items; Object.keys works best
        lsArray.forEach( lsKey => {
            if (lsKey && lsKey.includes(cache_key)) {
                const obj = getItem(lsKey);
                if(obj && typeof obj === 'object') lsArrayFiltered.push(getItem(lsKey));
            }
        });
        // Sort lsArrayFiltered by clInstance.id, highest first
        lsArrayFiltered.sort((a,b) => {
            if (a?.clInstance?.id > b?.clInstance?.id) return -1;
            if (a?.clInstance?.id < b?.clInstance?.id) return 1;
            return 0;
        });
        setMyInstanceDrafts(lsArrayFiltered);
        return;
    }
    useEffect(() => {
        getMyClInstances().then();
    },[]);

    // Delete confirmation
    const [delConfirmIsOpen, setDelConfirmIsOpen] = useState(false);
    const deleteCl = () => {
        setDelConfirmIsOpen(false);
        let lsArray = Object.keys(localStorage);
        lsArray.forEach( lsKey => {
            if (lsKey && lsKey.includes(cache_key)) {
                localStorage.removeItem(lsKey);
            }
        });
        getMyClInstances().then();
    }

    /* Delete all locally stored data */
    const deleteAllLocalData = () => {
        // Close any open modals or popups
        setDelConfirmIsOpen(false);

        /* Clean Reset */
        storageCleanReset();

    };


    return (<>
        <Page renderToolbar={()=>
            <Toolbar>
                <div className="left">
                    <BackButton onClick={goBack}>Back</BackButton>
                </div>
                <div className="center">
                    <small>{dateRT}</small>
                </div>
                <div className="right">
                    {/* RHS */}
                </div>
            </Toolbar>
        }>
            <div className="p-l-r-15 m-b-20 m-t-15">
                <h3 style={{lineHeight:'1.32', borderLeft:'3px solid #1e88e5',paddingLeft:12}}>
                    <span>My Unsubmitted Checklists</span>
                    <small className="opa-050 d-b f-s-094r">List of locally saved drafts, oldest first</small>
                </h3>
                { ((!myInstanceDrafts) || (myInstanceDrafts && !myInstanceDrafts.length)) &&
                <div className="flex flex-both-center" style={{height:'100%',minHeight:'56vh'}}>
                    { !myInstanceDrafts && <span><ProgressCircular modifier="material" indeterminate={true} /></span> }
                    { myInstanceDrafts && !myInstanceDrafts.length && <span>None found</span> }
                </div>
                }
                {
                (myInstanceDrafts && myInstanceDrafts?.length>0) &&
                <List
                    dataSource={myInstanceDrafts}
                    renderHeader={renderListHeader}
                    renderFooter={renderListFooter}
                    renderRow={(data, i) => (
                    <ListItem key={i} modifier={(i===(myInstanceDrafts?.length-1))?'longdivider':null} onClick={()=> {
                        console.log('navigating to details with data',data);
                        navigate(paths.checklist.details, {
                            state: {
                                data: {
                                    localDecode: true,
                                    clInstance_id: data?.clInstance?.id ?? 0
                                }
                            }
                        })
                    }
                    } style={{borderBottom:'1px solid #cdcdcd'}}>
                        <div className="p-r-15">
                            <strong>{i + 1}.</strong> {data?.clInstance?.name??''}
                            <small className="d-b f-s-09r p-t-10 col-grey-dark">
                                <b>ID: {data?.clInstance?.id??0}</b>
                                <br/>
                                Last saved: {makeDate_Std(data?.timestamp??new Date().toISOString())}
                                {/*&nbsp;&bull;&nbsp;*/}
                            </small>
                        </div>
                    </ListItem>
                    )}
                />
                }

                {/* Delete saved checklists / LS */}
                {/*(myInstanceDrafts && myInstanceDrafts?.length>0) &&*/}
                <div className="m-t-b-20 d-b">
                    <Button onClick={()=>setDelConfirmIsOpen(true)} style={{backgroundColor: "#753020", borderColor: "#753020"}}>Delete all</Button>
                </div>

                <AlertDialog isOpen={delConfirmIsOpen} >
                   <div className="alert-dialog-title">Confirm</div>
                   <div className="alert-dialog-content">
                    Delete all saved drafts?
                   </div>
                  <div className="alert-dialog-footer">  
                      <Button onClick={deleteCl} className="alert-dialog-button" style={{clear:'both',display:'block'}}>
                       Delete un-submitted checklists
                       </Button>
                      <Button onClick={deleteAllLocalData} className="alert-dialog-button" style={{clear:'both',display:'block'}}>
                       Delete everything (Force Reset)
                       </Button>
                       <Button onClick={()=>setDelConfirmIsOpen(false)} className="alert-dialog-button" style={{clear:'both',display:'block'}}>
                       Cancel
                      </Button>
                  </div>
                 </AlertDialog>
            </div>
        </Page>
    </>);

}
