import React, { useState, useMemo, useEffect } from "react";
import { setup } from 'axios-cache-adapter';
import { ProgressBar } from "react-onsenui";
import { API_BASE } from "../config.common";

// ----------------------------------------------------------------------

export const axiosInstance = setup({
    baseURL: API_BASE,
    validateStatus: function (status) {
        return (status >= 200 && status < 300);
    },
    // `axios-cache-adapter` options
    cache: {
        maxAge: 0.05*(60*1000), // ms
        limit: false,
        exclude: {
            query: false,
        },
        debug: false,
    }
});

export const axiosInstanceHttp = setup({
    
    validateStatus: function (status) {
        return (status >= 200 && status < 300);
    },
    // `axios-cache-adapter` options
    cache: {
        maxAge: 0.05*(60*1000), // ms
        limit: false,
        exclude: {
            query: false,
        },
        debug: false,
    }
});

export const useAxiosLoader = () => {
    const [loadingCounter, setCounter] = useState(0);

    const interceptors = useMemo(() => {
        const inc = () => setCounter(loadingCounter + 1);
        const dec = () => setCounter(loadingCounter - 1);
        return ({
            request: (config) => (inc(), config),
            response: (response) => (dec(), response),
            error: (error) => (dec(), Promise.reject(error)),
        });
    }, []); // create the interceptors

    useEffect(() => {
        // add request interceptors
        const reqInterceptor = axiosInstance.interceptors.request.use(interceptors.request, interceptors.error);
        // add response interceptors
        const resInterceptor = axiosInstance.interceptors.response.use(interceptors.response, interceptors.error);
        return () => {
            // remove all intercepts when done
            axiosInstance.interceptors.request.eject(reqInterceptor);
            axiosInstance.interceptors.response.eject(resInterceptor);
        };
    }, [interceptors]);

    return [loadingCounter > 0];
};

export const useAxiosMessages = () => {
    const [messages, setMessages] = useState([]);
    const [type, setType] = useState("error");

    const interceptors = useMemo(() => {
        const inc = () => setMessages([]);
        const dec = (res,type) => {
            let messagesTemp = [];
            let typeTemp = type;
            switch(type) {
                case 'success':
                    messagesTemp = res.data?.messages?.length?res.data.messages:[];
                    break;
                case 'error':
                    messagesTemp = res.response?.data?.messages?.length?res.response.data.messages:[];
                    break;
            }
            /* Laravel Validation Errors */
            /* Since this will always be an ERROR status code, data would be in res.response.data */
            if (typeof res?.response?.data?.errors !== 'undefined') {
                let laraValidationErrors = [];
                for (let key in res.response.data.errors) {
                    res.response.data.errors[key].forEach( (err) => {laraValidationErrors.push(err)} )
                }
                messagesTemp.push(...laraValidationErrors);
            }
            return (setType(typeTemp), setMessages(messagesTemp));
        }
        return ({
            request: (config) => (inc(), config),
            response: (response) => (dec(response,"success"),response),
            error: (error) => (dec(error,"error"),Promise.reject(error)),
        });
    }, []); // create the interceptors

    useEffect(() => {
        // add request interceptors
        const reqInterceptor = axiosInstance.interceptors.request.use(interceptors.request, interceptors.error);
        // add response interceptors
        const resInterceptor = axiosInstance.interceptors.response.use(interceptors.response, interceptors.error);
        return () => {
            // remove all intercepts when done
            axiosInstance.interceptors.request.eject(reqInterceptor);
            axiosInstance.interceptors.response.eject(resInterceptor);
        };
    }, [interceptors]);

    return {
        type: type,
        messages: messages||[]
    };
};


export default function ApiLoading() {
    const [loading] = useAxiosLoader();
    const message = useAxiosMessages();

    const config = {
        useHtmlMessages: false,
    }

    let msgStr = '';

    if(message && message.messages && message.messages.length) {
        message.messages.forEach( (m,i) => {
        //if(m.length!==i+1) msgStr += " • ";
        msgStr +=
            ('success'===message.type)?"Success":"Info" +
            // eslint-disable-next-line no-useless-concat
            `: ${m}` + "\n";
        });
        if(config.useHtmlMessages) msgStr += ` <br />`;
        alert(msgStr);
    }
    return (
        <>
            { loading &&
            <div style={{position:"absolute", width: "100%", zIndex: 9000, top:0, left: 0}}>
                <ProgressBar indeterminate />
            </div>
            }
            {/*{
                showNoti &&
                <div className="flex flex-vert-center flex-horiz-space-bw notif-custom">
                    <div>
                        <div dangerouslySetInnerHTML={ { __html: msgStr }} />
                        {msg}
                    </div>
                    <div></div>
                </div>
            }*/}
        </>
    );
}

