/* VESTA MOBILE.WEB BUILD CONFIG (Builds FBS and CL for x config) */

export const getEnvVar = (keyName, defaultVal) => {
    const envVar = process.env[keyName] || process.env[`REACT_APP_${keyName}`];
    return envVar !== undefined ? envVar : defaultVal;
};

export const BUILDING_FOR = getEnvVar("BUILD_FOR", "VESTA")?.toUpperCase(); // Values: VESTA, VESTA_STAGING_AZURE_INT, VESTA_INDIA_AZ, AD

export const CONFIGS = {
    /* -------========== IMP: NO TRAILING SLASH ==========------- */
    // ~~~~~~ Vesta Main SaaS ~~~~~~
    VESTA: {
        /* API base (generic for all 'products') */
        API_BASE: "https://checkinn.co/api/v1",
        API_BASE_LOCAL_DEV: "http://cinext.localhost/api/v1",
        /* FBS URL base (For QR Code base URL) */
        URL_FBS: "https://fbs.checkinn.co",
        URL_FBS_LOCAL_DEV: "http://localhost:3000",
        URL_CL: "https://checklists.checkinn.co",
        URL_CL_LOCAL_DEV: "http://localhost:3000",
    },
    // ~~~~~~ Vesta Staging (Internal/Dev/Testing) ~~~~~~
    VESTA_STAGING_AZURE_INT: {
        /* API base (generic for all 'products') */
        API_BASE: "https://staging-platform.teamvesta.com/api/v1",
        API_BASE_LOCAL_DEV: "http://cinext.localhost/api/v1",
        /* FBS URL base (For QR Code base URL) */
        URL_FBS: "https://staging-feedback.teamvesta.com",
        URL_FBS_LOCAL_DEV: "http://localhost:3000",
        URL_CL: "https://staging-checklists.teamvesta.com",
        URL_CL_LOCAL_DEV: "http://localhost:3000",
        /* PS: Nov'24: Hosting to GH pages for staging. However, only one URL is allowed per GH repo.  */
    },
    // ~~~~~~ Vesta Azure India for IN Clients ~~~~~~
    VESTA_INDIA_AZ: {
        /* API base (generic for all 'products') */
        API_BASE: "https://platform-in.teamvesta.com/api/v1",
        API_BASE_LOCAL_DEV: "http://cinext.localhost/api/v1",
        /* FBS URL base (For QR Code base URL) */
        URL_FBS: "https://fbs.teamvesta.com",
        URL_FBS_LOCAL_DEV: "http://localhost:3000",
        URL_CL: "https://cl.teamvesta.com",
        URL_CL_LOCAL_DEV: "http://localhost:3000",
    },
    // ~~~~~~ AD (Private Cloud Deployment for Client: AD) ~~~~~~
    AD: {
        /* API base (generic for all 'products') */
        API_BASE: "https://atom-feedback-system.azurewebsites.net/api/v1",
        API_BASE_LOCAL_DEV: "http://cinext.localhost/api/v1",
        /* FBS URL base (For QR Code base URL) */
        URL_FBS: "https://atom-fbs-app.azurewebsites.net",
        URL_FBS_LOCAL_DEV: "http://localhost:3000",
        URL_CL: "", /* CL does not exist for AD deployment */
        URL_CL_LOCAL_DEV: "http://localhost:3000",
    },
}

export const PROFILE_IMG_FALLBACK = 'https://checkinn.co/app/assets/images/icons/userDefaultIcon.png';

export const isProduction = process.env.NODE_ENV === 'production' ?? true;
export const API_BASE = CONFIGS[BUILDING_FOR][isProduction ? 'API_BASE' : 'API_BASE_LOCAL_DEV'];
export const URL_BASE_FBS = CONFIGS[BUILDING_FOR][isProduction ? 'URL_FBS' : 'URL_FBS_LOCAL_DEV'];

export const COMPANY_BRAND_NAME = "Vesta";

export const getProductMetaObj = (hostname = window.location.hostname) => {
    return {
        product: getEnvVar("PRODUCT", "unknown"),
        productName: getEnvVar("PRODUCT_NAME", "Unknown Product"),
        companyBrandName: getEnvVar("COMPANY_BRAND_NAME", "Vesta"),
    };
}

