import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {Button, Page} from "react-onsenui";
import {paths} from "../App";
/* Anim */
import Lottie from "lottie-react";
import animChecklist from "../assets/checklist-confetti.json";
/* Sound Effects */
import useSound from "use-sound";
import sfxComplete from "../assets/complete.mp3";

export const ChecklistFilledScreen = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const goBackToDash = () => navigate(paths.checklist.dash);
    const [playComplete] = useSound(sfxComplete);
    setTimeout(()=>playComplete(),500);

    /* Handle browser back button (React way) */
    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault();
            // Navigate back to the same page, effectively disabling the back button
            navigate(location.pathname);
            console.log("Back button disabled");
        };
        // Add an event listener for the 'popstate' event
        window.addEventListener('popstate', handleBackButton);
        // Cleanup on unmounted
        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [navigate, location.pathname]);


    return (
        <Page>
            <div className="flex flex-both-center h-100-vh flex-dir-col">
                <div style={{margin:'-145px 0 -75px 0'}}>
                    <Lottie animationData={animChecklist} loop={true} style={{width: 350, height: 350}}/>
                </div>
                <h1 className="m-0 p-0">Completed</h1>
                <p className="m-0 p-0 m-b-5 d-b">Checklist submitted successfully</p>
                <Link to={paths.checklist.dash}>
                    <Button modifier="large--quiet" onClick={goBackToDash}>
                        &larr; Dashboard
                    </Button>
                </Link>
            </div>
        </Page>
    );
}
