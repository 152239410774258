import React from 'react';

export const useDate = () => {

    const getDate = () => (new Date().toLocaleTimeString());

    const [now, setDate] = React.useState(getDate()); // Save the current date to be able to trigger an update

    React.useEffect(() => {
        const timer = setInterval(() => { // Creates an interval which will update the current data every x seconds
            // This will trigger a rerender every component that uses the useDate hook.
            setDate(getDate());
        }, 1*1000);
        return () => {
            clearInterval(timer); // Return a function to clear the timer so that it will stop being called ON UNMOUNT
        }
    },[]);

    return now;

};
